<template>
  <div>
    <div class="modal_header">
      <el-row :gutter="10">
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <div class="grid-content bg-purple">
            <div class="title_h">
              {{ $t("message.update_country") }}
            </div>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <div class="grid-content bg-purple-light">
            <el-row class="button_r">
              <el-button
                type="success"
                :loading="loadingButton"
                :disabled="loadingButton ? true : false"
                @click="submit"
                >{{ $t("message.save_and_close") }}</el-button
              >
              <el-button @click="resetForm('form')">{{
                $t("message.close")
              }}</el-button>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="row">
      <div class="col-12 mt-2">
        <div class="modal-body">
          <el-form
            ref="form"
            status-icon
            :model="form"
            :rules="rules"
            :label-position="'left'"
          >
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item :label="columns.patient_id.title" prop="name">
                  <patients
                    v-model="form.patient_id"
                    :patient_id="form.patient_id"
                    size="medium"
                  ></patients>
                </el-form-item>
                <el-form-item :label="columns.comment.title" prop="code">
                  <el-input
                    v-model="form.comment"
                    autocomplete="off"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item :label="columns.discount.title" prop="code">
                  <el-input
                    v-model="form.discount"
                    type="number"
                    autocomplete="off"
                  ></el-input>
                </el-form-item>
                <el-form-item :label="columns.status.title" prop="name">
                  <el-switch
                    v-model="form.status"
                    active-color="#13ce66"
                    inactive-color="#ff4949"
                    active-text="Активный"
                    inactive-text="Не активный"
                  >
                  </el-switch>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import patients from "@/components/inventory-select/patients";
import form from "@/utils/mixins/form";
export default {
  mixins: [form],
  components: {
    patients,
  },
  props: {
    reloadModel: { type: Boolean, default: true },
  },
  data() {
    return {};
  },
  watch: {
    reloadModel: {
      handler: function () {
        if (this.reloadModel) {
          this.loadModel();
        }
      },
      deep: true,
    },
  },
  created() {},
  computed: {
    ...mapGetters({
      rules: "discountPatients/rules",
      model: "discountPatients/model",
      columns: "discountPatients/columns",
    }),
  },
  methods: {
    ...mapActions({
      save: "discountPatients/update",
    }),
    loadModel() {
      this.form = JSON.parse(JSON.stringify(this.model));
    },
    submit(close = true) {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.loadingButton = true;
          console.log(this.form);
          this.save(this.form)
            .then((res) => {
              this.loadingButton = false;
              this.$alert(res);
              this.parent().listChanged();
              this.resetForm("form");
            })
            .catch((err) => {
              this.loadingButton = false;
              this.$alert(err);
            });
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.$emit("c-close", { drawer: "drawerUpdate" });
    },
  },
};
</script>
